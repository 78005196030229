<template>
  <div>
      <!-- {{comparison}} -->
      <!-- <iframe :src="orderInfo.reportInfo.comparison"  frameborder="0" scrolling="no"></iframe> -->
      <!-- <iframe class="iframe" ref="iframe" :src="comparison"  frameborder="0" scrolling="no"></iframe> -->

      <div class="detailMenu">
        <div class="container">
          <div class="menuBox">
            <a href="javascript:;" @click="menuIndex = 1" :class="{'active':menuIndex==1}">原文标注报告</a>
            <a href="javascript:;" @click="menuIndex = 2" :class="{'active':menuIndex==2}">详细片段报告</a>
            <a href="javascript:;" @click="menuIndex = 3" :class="{'active':menuIndex==3}">全文比对报告</a>
          </div>
        </div>
      </div>

      <div class="detailContent">
        <div class="container">
          <iframe id="myIframe" class="iframe" :src="orderInfo.reportInfo.original + '#toolbar=0'" v-if="menuIndex == 1" frameborder="0" scrolling="auto"></iframe>
          <iframe class="iframe" :src="orderInfo.reportInfo.part + '#toolbar=0'" v-if="menuIndex == 2" frameborder="0" scrolling="auto"></iframe>
          <iframe class="iframe" :src="orderInfo.reportInfo.comparison + '#toolbar=0'" v-if="menuIndex == 3" frameborder="0" scrolling="auto"></iframe>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
        // comparison: '',
        menuIndex: 1,
        orderInfo: {},
    }
  },
  created() {
    // if(this.$route.query.comparison){
    //     // console.log('-------',this.$route.query.orderInfo);
    //     this.comparison = this.$route.query.comparison
    // }
     if(this.$route.query.orderInfo){
       console.log(this.$route.query.orderInfo);
       this.orderInfo = this.$route.query.orderInfo
     }
  },
  methods: {

  },

  // mounted() {
  //   this.setMyIframeHeight();
  //    window.addEventListener("resize", () => this.setMyIframeHeight(), false);
  // },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.setMyIframeHeight(), false);
  // },
  // methods: {
  //   setMyIframeHeight(){
  //       // 页面可视高-顶部实际高-底部实际高-多余部分高度
  //     let that = this;
  //     this.$nextTick(() => {
  //       let bodyHeight = document.body.clientHeight;
  //       let outHeight =parseFloat(bodyHeight)+ "px";
  //         console.log('获取到le',bodyHeight);
  //       //设置iframe页面高度 因为在iframe时页面已经生成，高度无法改变，需要在iframe生成后直接设置高度
  //       document.querySelector("#myIframe").style.height = outHeight;
  //     });
  //   }
  // }

}
</script>

<style lang="scss" scoped>
.iframe{
  width: 100%;
  height: 100%;
}
.detailMenu{
  height: 70px;
  background: linear-gradient(180deg, #F2F9FF 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 18px 0px rgba(184, 205, 251, 0.26);
  .menuBox{
    float: right;
    height: 70px;
    display: flex;
    align-items: center;
    a{
      display: block;
      height: 70px;
      line-height: 70px;
      margin-left: 40px;
      font-size: 18px;
      font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
      color: #000000;
    }
    a.active{
      color: #1353B4;
    }
  }
  
}
.detailContent{
  .container{
    height: 600px;
  }
}
</style>
